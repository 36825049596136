<template>
    <Navigation :title="this.package.name"/>
    <div class="pb-5 bg-finger">
        <div class="container" style="height: 150px">

        </div>
    </div>

    <div class="container-fluid" style="margin-top: -5em">
        <div class="card package-card">
            <div class="card-body">
                <p class="text-primary fw-bold package-name">{{this.package.name}}</p>
                <span class="fw-bold d-block package-target mb-3">Professional | Pelajar | Individu <br>
                    <span class="text-muted package-consultation">{{this.package.is_consultation == '1' ? "Plus Konsultasi":"Tanpa konsultasi"}}</span>
                </span>
                <span class="fw-bold fs-4">{{'Rp.'+Intl.NumberFormat('en-US').format(this.package.price)}}</span>
            </div>
        </div>
    </div>

    <div class="container-fluid mb-5 pb-3 padding-tab-content">
        <p class="text-center fw-bold fs-5">Fitur</p>
        <ul class="mt-4 feature">
            <li v-for="(fitur) in this.package.content" v-bind:key="fitur">
                {{fitur}}
            </li>
        </ul>
        <div class="mt-4 px-3">
            <div class="form-check" @click="toggleCounselor">
                <input class="form-check-input" type="checkbox" value="" id="check_counselor" v-bind:checked="this.package.is_consultation==='1'">
                <label class="form-check-label consult-check-label" for="check_counselor">
                    Plus konsultasi durasi 40 menit s.d. 1 jam
                </label>
            </div>
            <button class="btn btn-primary rounded-pill" @click="toggleCounselor" v-if="this.package.is_consultation==='1' && !selected_counselor.name">Pilih konselor</button>
            <span class="d-block" style="font-size: 10pt" v-if="selected_counselor.name">Konselor yang dipilih :
                <br>
                <img :src="`/img/counselor/team-member-${selected_counselor.id}.jpg`" class="rounded-circle" width="140"
                     alt=""> <br>
                [<b>{{consultation_type.toUpperCase()}}</b>] {{selected_counselor.name}} (biaya: {{'Rp.'+Intl.NumberFormat('en-US').format(selected_counselor.price)}})</span>
            <div v-if="selected_counselor.name">
                <button type="button" class="btn btn-primary rounded-pill"
                        @click="cancel_counselor">Batalkan
                </button>
            </div>
            <!--<div class="form-floating mt-2">
                <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                    <option v-for="language in languages" v-bind:key="language" :data-thumbnail="language.icon_url">
                        {{language.name}}
                    </option>
                </select>
                <label for="floatingSelect">Bahasa Report</label>
            </div>-->
            <!--<div class="btn-group dropup mt-3">
                <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Pilih bahasa report
                </button>
                <ul class="dropdown-menu">
                    <li v-for="language in languages" v-bind:key="language">
                        <a class="dropdown-item" href="#">
                            <img :src="language.icon_url" class="w-25" alt="">
                            {{language.name}}
                        </a>
                    </li>
                </ul>
            </div>-->
            <div class="d-flex justify-content-between align-items-center mt-3">
                <span class="badge rounded-pill bg-light text-dark border shadow fs-6 py-3">Total: {{'Rp.'+Intl.NumberFormat('en-US').format(totalPrice)}}</span>
                <button class="btn btn-primary rounded-pill fw-bold" @click="triggerPesan">Pesan <i
                        class="bi bi-chevron-double-right"></i></button>
            </div>
        </div>
    </div>

    <BottomNav/>

    <!-- Button trigger modal -->
    <button type="button" id="btn-open-modal" class="btn btn-primary d-none" data-bs-toggle="modal"
            data-bs-target="#exampleModal">
        Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Pilih Konselor</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <span class="d-block fw-bold mb-2">Konsultasi secara:</span>
                        <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" @click="this.consultation_type = 'online'" name="btnradio" id="btnradio1" autocomplete="off">
                            <label class="btn btn-outline-primary" for="btnradio1">Online</label>
                            <input type="radio" class="btn-check" @click="this.consultation_type = 'offline'" name="btnradio" id="btnradio2" autocomplete="off">
                            <label class="btn btn-outline-primary" for="btnradio2">Offline</label>

                            
                        </div>
                        <div id="online-counselor" v-if="this.consultation_type === 'online' ">
                            <div class="row">
                                <div v-for="(counselor, index) in counselors" v-bind:key="counselor"
                                     :class="index===0 ? 'col' : 'col'" class="text-center click-effect mb-3"
                                     data-bs-dismiss="modal" @click="select_counselor(index)">
                                    <img :src="`/img/counselor/team-member-${counselor.id}.jpg`"
                                         class="w-100 rounded-circle d-block mx-auto img-thumbnail shadow border" alt="">
                                    <span class="d-block fw-bold">{{counselor.name}}</span>
                                    <span>Rp.{{Intl.NumberFormat('en-US').format(counselor.price)}}</span>
                                    <div v-if="counselor.activeDays">
                                        <div class="mt-3">
                                            <b class="d-block">*notes:</b>
                                            <div v-for="day in counselor.activeDays" :key="day.day">
                                                <span class="d-block">{{day.day}}</span>
                                                <span class="d-block">{{day.hour}} WIB</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="offline-counselor" v-else-if="this.consultation_type==='offline'">
                            <!-- <div class="d-flex justify-content-center"> -->
                            <div class="row">
                                <!-- <div class="col-6 text-center click-effect mb-3"
                                     data-bs-dismiss="modal" @click="select_counselor(0)">
                                    <img :src="`/img/counselor/team-member-${this.counselors[0].id}.jpg`"
                                         class="w-100 rounded-circle d-block mx-auto img-thumbnail shadow border" alt="">
                                    <span class="d-block fw-bold">{{this.counselors[0].name}}</span>
                                    <span>Rp.{{Intl.NumberFormat('en-US').format(this.counselors[0].price)}}</span>
                                    <div v-if="this.counselors[0].activeDays">
                                        <div class="mt-3">
                                            <b>*notes:</b>
                                            <div v-for="day in this.counselors[0].activeDays" :key="day.day">
                                                <span class="d-block">{{day.day}}</span>
                                                <span class="d-block">{{day.hour}} WIB</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-6 offset-3 text-center click-effect mb-3"
                                     data-bs-dismiss="modal" @click="select_counselor(2)">
                                    <img :src="`/img/counselor/team-member-${this.counselors[2].id}.jpg`"
                                         class="w-100 rounded-circle d-block mx-auto img-thumbnail shadow border" alt="">
                                    <span class="d-block fw-bold">{{this.counselors[2].name}}</span>
                                    <span>Rp.{{Intl.NumberFormat('en-US').format(this.counselors[2].price)}}</span>
                                    <div v-if="this.counselors[2].activeDays">
                                        <div class="mt-3">
                                            <b>*notes:</b>
                                            <div v-for="day in this.counselors[2].activeDays" :key="day.day">
                                                <span class="d-block">{{day.day}}</span>
                                                <span class="d-block">{{day.hour}} WIB</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    import axios from 'axios'
    import Swal from 'sweetalert2'

    export default {
        name: "PackageDetail",
        components: {BottomNav, Navigation},
        data() {
            return {
                package: [],
                counselors: [
                    // {id: 1, name: 'Dedi Priadi', price: '600000'},
                    // {id: 2, name: 'Annas Baihaqi', price: '250000', activeDays: [{day: 'Sabtu', hour: '13:00 - 20:00'}, {day: 'Minggu', hour: '10:00 - 20:00' }]},
                    {id: 2, name: 'Annas Baihaqi', price: '300000', activeDays: [{day: 'Senin - Jum\'at', hour: '10:00 - 16:45'}, {day: 'Sabtu', hour: '10:00 - 14:45' }]},
                    {id: 3, name: 'Sheila Rumai Halida', price: '250000', activeDays: [{day: 'Senin - Jum\'at', hour: '10:00 - 16:45'}, {day: 'Sabtu', hour: '10:00 - 14:45' }]},
                    {id: 4, name: 'Lucky Pramarta', price: '250000', activeDays: [{day: 'Senin - Jum\'at', hour: '10:00 - 16:45'}, {day: 'Sabtu', hour: '10:00 - 14:45' }]},
                ],
                selected_counselor: {
                    id: '',
                    name: '',
                    price: '',
                },
                totalPrice: '',
                languages: [],
                selected_language: '',
                consultation_type: '',
            }
        },
        mounted() {
            document.body.style.backgroundColor = '#EEEFFF'
            this.package = this.$store.getters.getPackage
            this.totalPrice = this.package.price

            let self = this
            axios.get('https://api.priadi.id/api/language', {headers: {'x-api-key': 12345}})
                .then((response) => {
                    self.languages = response.data.language
                }).catch((error) => {
                console.log(error)
            })
        },
        unmounted() {
            document.body.style.backgroundColor = '#FFFFFF'
        },
        methods: {
            toggleCounselor() {
                document.getElementById('btn-open-modal').click()
                let checkbox = document.getElementById('check_counselor')
                checkbox.checked = false
            },
            select_counselor(id) {
                let checkbox = document.getElementById('check_counselor')
                checkbox.checked = true
                this.selected_counselor.id = this.counselors[id].id
                this.selected_counselor.name = this.counselors[id].name
                this.selected_counselor.price = this.counselors[id].price

                if(this.package.is_consultation==='0')
                    this.totalPrice = parseInt(this.package.price) + parseInt(this.selected_counselor.price)
                else
                    this.totalPrice = parseInt(this.package.price)
            },
            cancel_counselor() {
                let checkbox = document.getElementById('check_counselor')
                checkbox.checked = false
                this.selected_counselor = {
                    id: '',
                    name: '',
                    price: '',
                }
                this.totalPrice = parseInt(this.package.price)
            },
            triggerPesan() {
                this.package.counselor_id = this.selected_counselor.id
                this.package.counselor_name = this.selected_counselor.name
                this.package.counselor_price = this.selected_counselor.price
                this.package.total_price = this.totalPrice
                this.package.consultation_type = this.consultation_type

                if(this.package.is_consultation==='1')
                    if(!this.package.counselor_name)
                        Swal.fire('Error', 'Mohon pilih konselor sebelum melanjutkan','warning')
                    else
                        this.$router.push('/add_transaction')
                else
                    this.$router.push('/add_transaction')
            }
        }
    }
</script>

<style scoped>
    body {
        background-color: #2BBDC8;
    }

    .bg-finger {
        background: #08A0F1 url("/img/fpimageup.png");
        background-size: cover;
    }

    .package-card {
        border-radius: 20px;
        /*background-image: url('/img/fingershadow1.png');*/
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
    }

    .feature {
        column-width: auto;
        column-count: 2;
    }

    .feature li {
        list-style-type: '✔';
        padding-inline-start: 1ch;
    }

    .padding-tab-content {
        padding-top: 1em;
    }

    @media only screen and (min-width: 320px) {
        .padding-card-promo {
            padding-top: 1.5em;
        }

        .package-name {
            font-size: 10pt;
        }

        .package-target {
            font-size: 8pt;
        }

        .package-consultation {
            font-size: 7pt;
        }

        .package-price {
            font-size: 7pt;
        }

        .package-btn {
            font-size: 9pt;
        }

        .consult-check-label {
            font-size: 8pt;
        }
    }

    @media only screen and (min-width: 375px) {
        .padding-card-promo {
            padding-top: 1.5em;
        }

        .package-name {
            font-size: 12pt;
        }

        .package-target {
            font-size: 10pt;
        }

        .package-consultation {
            font-size: 9pt;
        }

        .package-price {
            font-size: 10pt;
        }

        .package-btn {
            font-size: 10pt;
        }

        .consult-check-label {
            font-size: 8pt;
        }
    }

    @media only screen and (min-width: 425px) {
        .padding-card-promo {
            padding-top: 2em;
        }

        .package-name {
            font-size: 13pt;
        }

        .package-target {
            font-size: 10pt;
        }

        .package-consultation {
            font-size: 10pt;
        }

        .package-price {
            font-size: 10pt;
        }

        .package-btn {
            font-size: 10pt;
        }

        .consult-check-label {
            font-size: 9pt;
        }
    }

    @media only screen and (min-width: 768px) {
        .padding-card-promo {
            padding-top: 3em;
        }

        .package-name {
            font-size: 18pt;
        }

        .package-target {
            font-size: 12pt;
        }

        .package-consultation {
            font-size: 12pt;
        }

        .package-price {
            font-size: 12pt;
        }

        .package-btn {
            font-size: 14pt;
        }

        .consult-check-label {
            font-size: 12pt;
        }
    }
</style>
